<template>
    <div>
        <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="left">
                  <div class="job_news_title">
                        <div class="img"><img src="../../assets/paint_icon.svg" alt="Icon"></div>
                        <div class="text_line_title">
                            <!--<span>{{ 'ajliin_bairnii_zar' | translate }}</span>-->
                            <span>{{ 'baiguullagin_ajliin_zar' | translate }}</span>
                            <svg class="svg_text_line">
                                <line x1="80" style="stroke:#EFBC11; stroke-width:3;"/>
                            </svg>
                          <a href="javascript:void(0)" @click="cleanFilter" class="clean_button">
                            <span>{{ 'clear' | translate }}</span>
                          </a>
                        </div>
                  </div>
                  <div class="tab_main">
                        <p>{{ 'baiguullaga' | translate }}</p>
                        <Select class="job_select" v-model="selectCompany"
                                :placeholder="'baiguullagaar_haih' | translate" filterable>
                            <Option v-for="(baiguullaga, index) in select_ds_baiguullaga" :value="baiguullaga.id" :key="index">{{ baiguullaga.ner_mn }}</Option>
                        </Select>
                        <p class="">{{'job' | translate }}</p>
                        <Select class="job_select" v-model="selectAJilTurul" :placeholder="'ajliin_turul' | translate " filterable>
                          <Option v-for="(turul, index) in lut_ajiliin_torol" :value="turul.id" :key="index">{{ turul.ajiliin_torol }}</Option>
                        </Select>
                        <p>{{ 'tsagiin_turul' | translate }}</p>
                        <Select class="job_select" v-model="selectTsagiinTurul" :placeholder="'tsagiin_turul_songoh' | translate">
                            <Option v-for="(ts_turul, index) in ds_tsag_view" :value="ts_turul.id" :key="index">{{ ts_turul.tsagiin_torol }}</Option>
                        </Select>
                        <p>{{ 'tsalin_huls' | translate }}</p>
                        <Select class="job_select" v-model="selectTsalinTurul" :placeholder="'tsalingiin_unelgee_songoh' | translate">
                            <Option v-for="(tsalin, index) in ds_tsalin_view" :value="tsalin.id" :key="index">{{ tsalin.tsalin_torol }}</Option>
                        </Select>

                        <p>{{ 'aimag_niislel' | translate }}</p>
                        <Select class="job_select" v-model="selectAimagNiislel"
                                on-select="getSumDuureg"
                                :placeholder="'aimagcapitalcity' | translate">
                          <Option v-for="(item, index) in aimag_niislel" :value="item.id" :key="index">{{ item.aimagname }}</Option>
                        </Select>

                        <p>{{ 'sum_duureg' | translate }}</p>
                        <Select class="job_select1" v-model="selectSumDuureg" :placeholder="'sumdistrict' | translate">
                          <Option v-for="(item, index) in SumDvvreg" :value="item.id" :key="index">{{ item.sumname }}</Option>
                        </Select>
                  </div>
                  <div class="search">
                        <Button @click="callDataCompany" class="search_btn"><i class="ivu-icon ivu-icon-ios-search"></i>{{ 'search' | translate }}</Button>
                  </div>
                </div>
              <!--banner-->
                <div class="c_banner">
                    <a v-if="banner3.unelgee"><img :src="IMAGE_URL + banner3.unelgee" alt="Image"></a>
                </div>
            </div>
            <!--Right Slide and table list-->

            <div class="col-md-8">
                <div class="right">
                    <div class="">
                        <div class="top_item">
                          <baiguullaga_slide></baiguullaga_slide>
                        </div>
                    </div>
                    <!--Right table list (Ажлын зарын лист)-->
                    <div class="" v-if="z_ajiliin_zar.length > 0">
                        <div class="p_table">
                          <table width="100%">
                                <tbody>
                                <!--tr-->
                                <tr v-for="(zar, zaIndex) in z_ajiliin_zar" :key="zaIndex">
                                    <td>
                                        <div class="company_logos" v-if="zar.torol_bagts">
                                            <img class="logo" src="../../assets/company_logo/1_gold.svg" alt=""
                                                 v-if="zar.torol_bagts === 'Алтан багц'">
                                            <img class="logo" src="../../assets/company_logo/1_silver.svg" alt=""
                                                 v-if="zar.torol_bagts === 'Мөнгөн багц'">
                                            <img class="logo" src="../../assets/company_logo/1_bronze.svg" alt=""
                                                 v-if="zar.torol_bagts === 'Хүрэл багц'">
                                        </div>
                                        <div class="company_logos" v-else>
                                            <img class="logo" src="../../assets/company_logo/2_gold.svg" alt=""
                                                 v-if="zar.torolbagts_gishuun_bus === 'Алтан багц'">
                                            <img class="logo" src="../../assets/company_logo/2_silver.svg" alt=""
                                                 v-if="zar.torolbagts_gishuun_bus === 'Мөнгөн багц'">
                                            <img class="logo" src="../../assets/company_logo/2_bronze.svg" alt=""
                                                 v-if="zar.torolbagts_gishuun_bus === 'Хүрэл багц'">
                                        </div>
                                        <div class="p_logo">
                                            <img :src="IMAGE_URL + zar.logo" alt="" v-if="zar.logo != ''">
                                            <img src="../../assets/no-image.png" alt="" v-else>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col_1 ">
                                            <router-link :to="`/b_a_z_details/${zar.id}`"><h5>{{ zar.ajiliin_torol }}</h5>
                                            </router-link>
                                            <div class="flex_col">
                                                <div class="sub_col_2">
                                                    <span class="company_n">{{ zar.ner_mnbaiguullaga }} </span>
                                                    <div class="text_bold">{{ 'where' | translate }}: <span
                                                            class="not_bold"> {{ zar.aimagname }} </span>
                                                    </div>
                                                </div>
                                                <div class="static_button ">
                                                    {{ zar.tsagiin_torol }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                      <save_button_ajil_zar :zar_id="zar.id" type="baiguullaga"></save_button_ajil_zar>
                                        <div class="td_3">
                                            <div class="td_3_1">
                                                <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                                <p class="diff_p_text">{{ zar.e_ognoo | date }}</p>
                                            </div>
                                            <div class="td_3_2">
                                                <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                                <p class="p_text ">{{ zar.d_ognoo | date }}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="page">
                            <Page @on-change="changePage"
                                  :current-page="current_page"
                                  :page-size="10"
                                  :total="total"
                                  size="small"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  <div v-else class="no-data">
                      {{ 'no_ads' | translate }}
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
  GET_AIMAG_NIISLEL,
  GET_AJIL_ZAR_PAGE_BY_ID, GET_AJILIIN_TURUL,
  GET_AJILIIN_ZAR_PAGE,
  GET_BAIGUULLAGA,
  GET_BANNER_VIEW, GET_SUM_DUUREG_ALL,
  GET_TSAGIIN_TURUL,
  GET_TSALIN_TURUL,
  IMAGE_URL,
} from "../../graphql/queries";
import {mapGetters} from "vuex";
const save_button_ajil_zar = () => import(/* webpackChunkName: "save_button_ajil_zar" */ '../section/save_button_ajil_zar');
const baiguullaga_slide = () => import(/* webpackChunkName: "baiguullaga_slide" */ '../section/baiguullaga_slide');
export default {
  components: {
    "save_button_ajil_zar": save_button_ajil_zar,
    "baiguullaga_slide": baiguullaga_slide,
  },
    data() {
        return {
            loading: true,
            IMAGE_URL: IMAGE_URL,
            ds_baiguullaga: [],
            select_ds_baiguullaga: [],
            ds_tsalin_view: [],
            ds_tsag_view: [],
            lut_ajiliin_torol: [],
            total: 0,
            current_page: 1,
            last_page: 1,
            selectCompany: '',
            selectAJilTurul: '',
            selectTsagiinTurul: '',
            selectTsalinTurul: '',
            z_ajiliin_zar: [],
            banner3: {},

            selectAimagNiislel: '',
            selectSumDuureg: '',
            aimag_niislel: [],
            sumduureg: [],
        }
    },
    computed: {
      ...mapGetters([
        'languages',
        'language',
        // ...
      ]),
      SumDvvreg(){
        if (this.selectAimagNiislel) {
          return this.sumduureg.filter(sumdvvreg => sumdvvreg.aimagid == this.selectAimagNiislel);
        } else {
          return this.sumduureg;
        }
      },
    },
    methods: {
        getGet_select_baiguullaga() {
            this.$apollo.query({query: GET_BAIGUULLAGA}).then(({data}) => {
                this.select_ds_baiguullaga = data.ds_baiguullaga;
            })
        },
        getTsagiinTurul() {
            this.$apollo.query({query: GET_TSAGIIN_TURUL}).then(({data}) => {
                this.ds_tsag_view = data.ds_tsag_view;
            })
        },
        getTsalinTurul() {
            this.$apollo.query({query: GET_TSALIN_TURUL}).then(({data}) => {
                this.ds_tsalin_view = data.ds_tsalin_view;
            })
        },
        getAjilTurul() {
           this.$apollo.query({query: GET_AJILIIN_TURUL}).then(({data}) => {
              this.lut_ajiliin_torol = data.lut_ajiliin_torol;
           })
        },
        callDataCompany() {
            // console.log(this.selectCompany);
            // console.log(this.selectTsagiinTurul);
            // console.log(this.selectTsalinTurul);
            // console.log(this.selectAJilTurul);
            this.loading = true;
            if (this.selectCompany || this.selectTsagiinTurul || this.selectTsalinTurul || this.selectAJilTurul || this.selectAimagNiislel || this.selectSumDuureg) {
                this.$apollo.query({query: GET_AJIL_ZAR_PAGE_BY_ID, fetchPolicy: 'no-cache',
                    variables: {
                        page: this.current_page, size: 10,
                        baiguullaga_id: this.selectCompany.toString(),
                        ts_turul_id: this.selectTsagiinTurul.toString(),
                        tsalin_turul_id: this.selectTsalinTurul.toString(),
                        ajiliin_turul_id: this.selectAJilTurul.toString(),
                        aimag_niislel: this.selectAimagNiislel.toString(),
                        sum_duureg: this.selectSumDuureg.toString(),
                    }
                }).then(({data}) => {
                    this.z_ajiliin_zar = data.paginate.ds_ajiliin_zar_view;
                    this.total = data.paginate.total;
                    setTimeout(() => {
                        this.loading = false;
                    })
                })
            } else {
                this.loading = true;
                this.$apollo.query({
                    query: GET_AJILIIN_ZAR_PAGE, fetchPolicy: 'no-cache',
                    variables: {page: this.current_page, size: 10,}
                }).then(({data}) => {
                    this.z_ajiliin_zar = data.paginate.ds_ajiliin_zar_view;
                    this.total = data.paginate.total;
                    setTimeout(() => {
                     this.loading = false;
                    })
                })
            }
        },
        cleanFilter() {
           this.selectCompany =  '';
           this.selectAJilTurul =  '';
           this.selectTsagiinTurul =  '';
           this.selectAJilTurul =  '';
           this.selectAimagNiislel =  '';
           this.selectSumDuureg =  '';
           this.callDataCompany();
        },
        changePage(page) {
            this.current_page = page;
            this.callDataCompany();
        },
        getBanner() {
            this.$apollo.query({query: GET_BANNER_VIEW}).then(({data}) => {
                this.banner3 = data.ds_banner_view[2];
            })
        },
      getAimagNiislel(){
        this.$apollo.query({ query: GET_AIMAG_NIISLEL}).then(({data}) => {
          this.aimag_niislel = data.ds_aimag_view;
        })
      },
      getSumDuureg(){
        this.$apollo.query({query: GET_SUM_DUUREG_ALL}).then(({data}) => {
          this.sumduureg = data.ds_sum_view;
        })
      },
    },

    mounted() {
        this.getTsagiinTurul();
        this.getAjilTurul();
        this.getTsalinTurul();
        this.callDataCompany();
        this.getBanner();
        this.getGet_select_baiguullaga();
        this.getAimagNiislel();
        this.getSumDuureg();
    },

}
</script>

<style scoped>

</style>



